import { getBookmarkCollections } from 'src/models/bookmarkCollection'
import useSwr, { mutate, useSWRConfig } from 'swr'

export { setBookmarkCollections, clearCache } from './setters'
export const generateBookmarksUserKey = (userId) =>
  `/api/v3/bookmarks/${userId ? userId : `me/${obe.storage_keys.swr_me_key}`}`
export const loadBookmarkCollections = (userId) => mutate(generateBookmarksUserKey(userId))

export const useBookmarkCollections = (userId) => {
  const USER_KEY = generateBookmarksUserKey(userId)
  const { cache } = useSWRConfig()

  const { data, error } = useSwr(USER_KEY, () => getBookmarkCollections(userId), {
    revalidateOnMount: !cache.has(USER_KEY),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  const isLoadingInitialState = !data && !error
  const defaultResponse = {
    bookmarkCollections: [],
    isLoadingInitialState,
  }

  if (error || !data) {
    return defaultResponse
  }

  const bookmarkCollections = data

  return {
    isLoadingInitialState,
    bookmarkCollections,
  }
}

export default useBookmarkCollections
