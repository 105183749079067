import { client, api } from 'src/api'

export const addVideoToBookmarkCollection = (obeClassId, bookmarkCollectionId) =>
  client.post('/api/v4/collection_videos', {
    bookmarkCollectionId,
    obeClassId,
  })

export const bulkCopyClassesToCollection = (obeClassIds, bookmarkCollectionId) =>
  client.post('/api/v4/collection_videos/bulk_create', {
    bookmarkCollectionId,
    obeClassIds,
  })

export const bulkDeleteClassesFromCollection = (obeClassIds, bookmarkCollectionId) =>
  client.post('/api/v4/collection_videos/bulk_destroy', {
    bookmarkCollectionId,
    obeClassIds,
  })

export const bulkMoveClassesToCollection = (
  obeClassIds,
  oldBookmarkCollectionId,
  newBookmarkCollectionId
) =>
  client.post('/api/v4/collection_videos/bulk_update', {
    newBookmarkCollectionId,
    obeClassIds,
    oldBookmarkCollectionId,
  })

export const deleteVideoFromCollection = (obeClassId, bookmarkCollectionId) =>
  client.delete('/api/v4/collection_videos', {
    data: {
      bookmarkCollectionId,
      obeClassId,
    },
  })

export const createBookmarkCollection = (name, thumbnailImage, privacySetting = 'public') =>
  client.post('/api/v3/bookmarks', { name, thumbnailImage, privacySetting })

export const deleteBookmarkCollection = (id) => client.delete(`/api/v3/bookmarks/${id}`)

export const updateBookmarkCollection = (id, name, privacySetting, thumbnailImage) =>
  client.put(`/api/v3/bookmarks/${id}`, { name, privacySetting, thumbnailImage })

export const getBookmarkCollectionById = (id, includeFollowers = false, page = 1, perPage = 24) =>
  client.get(`/api/v3/bookmarks/${id}`, {
    params: {
      include_followers: includeFollowers,
      include_updates: true,
      page,
      per_page: perPage,
    },
  })

export const getObeClassesInBookmarkCollection = (id, page, perPage) =>
  client.get(`/api/v3/bookmarks/${id}/obe_classes`, {
    params: {
      page,
      per_page: perPage,
    },
  })

export const followBookmarkCollection = (bookmarkCollectionId) =>
  client.post('/api/v3/collection_follows', { collection_id: bookmarkCollectionId })

export const unfollowBookmarkCollection = (id) => client.delete(`/api/v3/collection_follows/${id}`)

export const getBookmarkCollections = async (userId) => {
  let response
  let bookmarkCollections

  if (api.sessionState) {
    try {
      response = await client.get('/api/v3/bookmarks', {
        params: {
          for_member: userId || null,
          include_all_collection: false,
          include_followers: false,
          include_obe_classes_first_page: false,
          include_updates: userId ? false : true,
        },
      })
    } catch (error) {
      return []
    }

    if (response?.data) {
      const { data } = response
      bookmarkCollections = data
    }

    return bookmarkCollections
  }
}
